var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        { staticClass: "accordion mb-2", attrs: { role: "tablist" } },
        [
          _vm.pos == "Entretien"
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-1",
                              modifiers: { "accordion-1": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Ressources Humaines (Entretien) ")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.canAny(["validate_gs_ods", "confirm_gs_ods"])
                                ? _c("span", [
                                    _vm._v(
                                      "Montant : " +
                                        _vm._s(
                                          new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "DZD"
                                          }).format(_vm.AmountPostes)
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c("div", { staticClass: "p-3" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Faire une demande")
                                ]),
                                _c("br"),
                                _c("switches", {
                                  staticClass: "ms-1",
                                  attrs: { "type-bold": "true", color: "info" },
                                  model: {
                                    value: _vm.addPersonel,
                                    callback: function($$v) {
                                      _vm.addPersonel = $$v
                                    },
                                    expression: "addPersonel"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-lg-6" },
                              [
                                _c(
                                  "b-card",
                                  {
                                    attrs: { title: "Personnel Contractuels" }
                                  },
                                  [
                                    _c("b-card-text", [
                                      _c(
                                        "table",
                                        { staticClass: "table mt-2" },
                                        [
                                          _c("thead", [
                                            _c("th", [_vm._v("Poste")]),
                                            _c(
                                              "th",
                                              { staticClass: "text-center" },
                                              [_vm._v("à la demander")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "text-center" },
                                              [_vm._v("Salaire Journalier")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "text-right" },
                                              [_vm._v("Nombres")]
                                            )
                                          ]),
                                          _vm._l(_vm.PostesList, function(
                                            poste,
                                            i
                                          ) {
                                            return _c("tr", { key: i }, [
                                              _c("th", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      poste.job_title
                                                        .designation
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              poste.estimation_type ==
                                              "when_ordering"
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-success text-center"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-check-circle text-success"
                                                      })
                                                    ]
                                                  )
                                                : _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-center"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-times-circle text-danger "
                                                      })
                                                    ]
                                                  ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        new Intl.NumberFormat(
                                                          "de-DE",
                                                          {
                                                            style: "currency",
                                                            currency: "DZD"
                                                          }
                                                        ).format(
                                                          poste.unit_price
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        poste.estimated_qty
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          })
                                        ],
                                        2
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.addPersonel
                              ? _c(
                                  "div",
                                  { staticClass: "col-lg-6" },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        attrs: {
                                          title: "Personnel à la demande"
                                        }
                                      },
                                      [
                                        _vm._l(_vm.fields, function(field, i) {
                                          return _c(
                                            "div",
                                            { key: i, staticClass: "row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-8" },
                                                [
                                                  _c(
                                                    "label",
                                                    { attrs: { for: "" } },
                                                    [_vm._v("Poste à Demander")]
                                                  ),
                                                  _c("multiselect", {
                                                    staticClass: "here",
                                                    attrs: {
                                                      openDirection: "below",
                                                      "deselect-label": "",
                                                      "track-by": "id",
                                                      label: "name",
                                                      "custom-label":
                                                        _vm.customLabel,
                                                      "show-labels": false,
                                                      placeholder: "",
                                                      options:
                                                        _vm.PostesListOrdering,
                                                      searchable: false,
                                                      "allow-empty": false
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fields[i].poste,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.fields[i],
                                                          "poste",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "fields[i].poste"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-2" },
                                                [
                                                  _c(
                                                    "label",
                                                    { attrs: { for: "" } },
                                                    [_vm._v("Nombres ")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.fields[i].qte,
                                                        expression:
                                                          "fields[i].qte"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "number",
                                                      min: "0",
                                                      placeholder: "20"
                                                    },
                                                    domProps: {
                                                      value: _vm.fields[i].qte
                                                    },
                                                    on: {
                                                      keypress: function(
                                                        $event
                                                      ) {
                                                        return _vm.isNumber(
                                                          $event
                                                        )
                                                      },
                                                      input: [
                                                        function($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.fields[i],
                                                            "qte",
                                                            $event.target.value
                                                          )
                                                        },
                                                        _vm.TotalAmount
                                                      ]
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-md-2  mt-4"
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "btn text-danger",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteRow(
                                                            i
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "far fa-trash-alt font-size-24"
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }),
                                        _c("div", { staticClass: "row mt-2" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col text-right" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn btn-info",
                                                  attrs: { type: "button" },
                                                  on: { click: _vm.AddformData }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-plus-circle"
                                                  }),
                                                  _vm._v(" Ajouter")
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.pos == "Entretien" && _vm.retrieveEQP == 1
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-2",
                              modifiers: { "accordion-2": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Ressources Materiels")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.canAny(["validate_gs_ods", "confirm_gs_ods"])
                                ? _c("span", [
                                    _vm._v(
                                      "Montant : " +
                                        _vm._s(
                                          new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "DZD"
                                          }).format(_vm.AmountEquipments)
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-2",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Faire une demande")
                              ]),
                              _c("br"),
                              _c("switches", {
                                staticClass: "ms-1",
                                attrs: { "type-bold": "true", color: "info" },
                                model: {
                                  value: _vm.addEQP,
                                  callback: function($$v) {
                                    _vm.addEQP = $$v
                                  },
                                  expression: "addEQP"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-lg-6" },
                            [
                              _c(
                                "b-card",
                                { attrs: { title: "Materiels Contractuels" } },
                                [
                                  _c("b-card-text", [
                                    _c(
                                      "table",
                                      { staticClass: "table mt-2" },
                                      [
                                        _c("thead", [
                                          _c("th", [_vm._v("Materiels")]),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("à la demander ?")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-right" },
                                            [_vm._v("Nombres")]
                                          )
                                        ]),
                                        _vm._l(_vm.EquipmentsList, function(
                                          materiel,
                                          i
                                        ) {
                                          return _c("tr", { key: i }, [
                                            _c("th", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    materiel.equipments.name
                                                  ) +
                                                  " "
                                              )
                                            ]),
                                            materiel.estimation_type ==
                                            "when_ordering"
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-success text-center"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-check-circle text-success"
                                                    })
                                                  ]
                                                )
                                              : _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-center"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-times-circle text-danger "
                                                    })
                                                  ]
                                                ),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      materiel.estimated_qty
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        })
                                      ],
                                      2
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.addEQP
                            ? _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c(
                                    "b-card",
                                    {
                                      attrs: { title: "Materiels à demander" }
                                    },
                                    [
                                      _vm._l(_vm.selectedMaterials, function(
                                        selectedMaterial,
                                        i
                                      ) {
                                        return _c(
                                          "div",
                                          { key: i, staticClass: "row mt-3" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-8" },
                                              [
                                                _c(
                                                  "label",
                                                  { attrs: { for: "" } },
                                                  [_vm._v("Liste Materiels")]
                                                ),
                                                _c(
                                                  "multiselect",
                                                  {
                                                    staticClass: "here",
                                                    attrs: {
                                                      placeholder: "",
                                                      openDirection: "below",
                                                      maxHeight: 500,
                                                      options:
                                                        _vm.EQPListOrdering,
                                                      multiple: false,
                                                      "group-select": false,
                                                      "track-by": "id",
                                                      "custom-label":
                                                        _vm.customLabelEQP
                                                    },
                                                    model: {
                                                      value:
                                                        selectedMaterial.equipment,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          selectedMaterial,
                                                          "equipment",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedMaterial.equipment"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "noResult"
                                                        },
                                                        slot: "noResult"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Oops! No elements found. Consider changing the search query."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-2" },
                                              [
                                                _c(
                                                  "label",
                                                  { attrs: { for: "" } },
                                                  [_vm._v("Quantité ")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        selectedMaterial.qte,
                                                      expression:
                                                        "selectedMaterial.qte"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    placeholder: "20"
                                                  },
                                                  domProps: {
                                                    value: selectedMaterial.qte
                                                  },
                                                  on: {
                                                    keypress: function($event) {
                                                      return _vm.isNumber(
                                                        $event
                                                      )
                                                    },
                                                    input: [
                                                      function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          selectedMaterial,
                                                          "qte",
                                                          $event.target.value
                                                        )
                                                      },
                                                      _vm.TotalAmount
                                                    ]
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-2  mt-4" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn text-danger",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteMatRow(
                                                          i
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "far fa-trash-alt font-size-24"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      _c("div", { staticClass: "row mt-3" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col text-right" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-info",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: _vm.AddformDataMat
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-plus-circle"
                                                }),
                                                _vm._v(" Ajouter")
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.pos == "Fournitures des produits phytosanitaires"
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-3",
                              modifiers: { "accordion-3": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Produits")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.canAny(["validate_gs_ods", "confirm_gs_ods"])
                                ? _c("span", [
                                    _vm._v(
                                      "Montant : " +
                                        _vm._s(
                                          new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "DZD"
                                          }).format(_vm.TotalAmount())
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-3",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        {
                          staticClass: "shadow-lg",
                          staticStyle: { heigth: "200px" }
                        },
                        [
                          _vm._l(_vm.selectedProducts, function(
                            selectedProduct,
                            i
                          ) {
                            return _c("div", { key: i, staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Liste Produits")
                                  ]),
                                  _c(
                                    "multiselect",
                                    {
                                      staticClass: "here",
                                      attrs: {
                                        openDirection: "below",
                                        maxHeight: 500,
                                        options: _vm.ProductsList,
                                        multiple: false,
                                        "group-select": false,
                                        placeholder: "Type to search",
                                        "track-by": "id",
                                        label: "name"
                                      },
                                      model: {
                                        value: selectedProduct.product,
                                        callback: function($$v) {
                                          _vm.$set(
                                            selectedProduct,
                                            "product",
                                            $$v
                                          )
                                        },
                                        expression: "selectedProduct.product"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "noResult" },
                                          slot: "noResult"
                                        },
                                        [_vm._v("Aucun Resultat trouvé.")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              selectedProduct.product.uom
                                ? _c("div", { staticClass: "col-md-2" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Unité")
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            selectedProduct.product.uom
                                              .designation,
                                          expression:
                                            "selectedProduct.product.uom.designation"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text", disabled: "" },
                                      domProps: {
                                        value:
                                          selectedProduct.product.uom
                                            .designation
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            selectedProduct.product.uom,
                                            "designation",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "col-md-2" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Quantité ")
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: selectedProduct.qte,
                                      expression: "selectedProduct.qte"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "number", placeholder: "20" },
                                  domProps: { value: selectedProduct.qte },
                                  on: {
                                    keypress: function($event) {
                                      return _vm.isNumber($event)
                                    },
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          selectedProduct,
                                          "qte",
                                          $event.target.value
                                        )
                                      },
                                      _vm.TotalAmount
                                    ]
                                  }
                                })
                              ]),
                              selectedProduct.product
                                ? _c("div", { staticClass: "col-md-2" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Délai")
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.period,
                                          expression: "period"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        disabled: "",
                                        readonly: ""
                                      },
                                      domProps: { value: _vm.period },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.period = $event.target.value
                                        }
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "col-md-2  mt-4" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn text-danger",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteProductRow(i)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "far fa-trash-alt font-size-24"
                                    })
                                  ]
                                )
                              ])
                            ])
                          }),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c("div", { staticClass: "col text-right" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-info",
                                  attrs: { type: "button" },
                                  on: { click: _vm.AddformDataProduct }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-plus-circle"
                                  }),
                                  _vm._v(" Ajouter")
                                ]
                              )
                            ])
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.pos == "Matériel roulant"
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-2",
                              modifiers: { "accordion-2": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v("Matériel roulant")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.canAny(["validate_gs_ods", "confirm_gs_ods"])
                                ? _c("span", [
                                    _vm._v(
                                      "Montant : " +
                                        _vm._s(
                                          new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "DZD"
                                          }).format(_vm.AmountMTR)
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-2",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Faire une demande")
                              ]),
                              _c("br"),
                              _c("switches", {
                                staticClass: "ms-1",
                                attrs: { "type-bold": "true", color: "info" },
                                model: {
                                  value: _vm.addMTR,
                                  callback: function($$v) {
                                    _vm.addMTR = $$v
                                  },
                                  expression: "addMTR"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-lg-6" },
                            [
                              _c(
                                "b-card",
                                { attrs: { title: "Materiels Contractuels" } },
                                [
                                  _c("b-card-text", [
                                    _c(
                                      "table",
                                      { staticClass: "table mt-2" },
                                      [
                                        _c("thead", [
                                          _c("th", [_vm._v("Materiels")]),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("à la demander ?")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-right" },
                                            [_vm._v("Nombres")]
                                          )
                                        ]),
                                        _vm._l(_vm.MaterialsList, function(
                                          materiel,
                                          i
                                        ) {
                                          return _c("tr", { key: i }, [
                                            _c("th", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    materiel.equipments.name
                                                  ) +
                                                  " "
                                              )
                                            ]),
                                            materiel.estimation_type ==
                                            "when_ordering"
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-success text-center"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-check-circle text-success"
                                                    })
                                                  ]
                                                )
                                              : _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-center"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-times-circle text-danger "
                                                    })
                                                  ]
                                                ),
                                            _c(
                                              "td",
                                              { staticClass: "text-right" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      materiel.estimated_qty
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        })
                                      ],
                                      2
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.addMTR
                            ? _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c(
                                    "b-card",
                                    {
                                      attrs: { title: "Materiels à demander" }
                                    },
                                    [
                                      _vm._l(_vm.selectedMTR, function(
                                        selectedMaterial,
                                        i
                                      ) {
                                        return _c(
                                          "div",
                                          { key: i, staticClass: "row mt-3" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-8" },
                                              [
                                                _c(
                                                  "label",
                                                  { attrs: { for: "" } },
                                                  [_vm._v("Liste Materiels")]
                                                ),
                                                _c(
                                                  "multiselect",
                                                  {
                                                    staticClass: "here",
                                                    attrs: {
                                                      placeholder: "",
                                                      openDirection: "below",
                                                      maxHeight: 500,
                                                      options:
                                                        _vm.MTRListOrdering,
                                                      multiple: false,
                                                      "group-select": false,
                                                      "track-by": "id",
                                                      "custom-label":
                                                        _vm.customLabelEQP
                                                    },
                                                    model: {
                                                      value:
                                                        selectedMaterial.equipment,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          selectedMaterial,
                                                          "equipment",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedMaterial.equipment"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "noResult"
                                                        },
                                                        slot: "noResult"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Aucun Résultat Trouvé."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-2" },
                                              [
                                                _c(
                                                  "label",
                                                  { attrs: { for: "" } },
                                                  [_vm._v("Quantité ")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        selectedMaterial.qte,
                                                      expression:
                                                        "selectedMaterial.qte"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    placeholder: "20"
                                                  },
                                                  domProps: {
                                                    value: selectedMaterial.qte
                                                  },
                                                  on: {
                                                    keypress: function($event) {
                                                      return _vm.isNumber(
                                                        $event
                                                      )
                                                    },
                                                    input: [
                                                      function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          selectedMaterial,
                                                          "qte",
                                                          $event.target.value
                                                        )
                                                      },
                                                      _vm.TotalAmount
                                                    ]
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-2  mt-4" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn text-danger",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteMTRRow(
                                                          i
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "far fa-trash-alt font-size-24"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      _c("div", { staticClass: "row mt-3" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col text-right" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-info",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: _vm.AddformDataMTR
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-plus-circle"
                                                }),
                                                _vm._v(" Ajouter")
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.pos == "Réalisation"
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-3",
                              modifiers: { "accordion-3": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v("Réalisation")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.canAny(["validate_gs_ods", "confirm_gs_ods"])
                                ? _c("span", [
                                    _vm._v(
                                      "Montant : " +
                                        _vm._s(
                                          new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "DZD"
                                          }).format(_vm.AmountReal)
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-3",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        {
                          staticClass: "shadow-lg",
                          staticStyle: { heigth: "200px" }
                        },
                        [
                          _vm._l(_vm.selectedReal, function(
                            selectedProduct,
                            i
                          ) {
                            return _c("div", { key: i, staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Liste des Réalisations")
                                  ]),
                                  _c(
                                    "multiselect",
                                    {
                                      staticClass: "here",
                                      attrs: {
                                        openDirection: "below",
                                        maxHeight: 500,
                                        options: _vm.implementations,
                                        multiple: false,
                                        "group-select": false,
                                        placeholder: "Type to search",
                                        "track-by": "id",
                                        label: "name"
                                      },
                                      model: {
                                        value: selectedProduct.realize,
                                        callback: function($$v) {
                                          _vm.$set(
                                            selectedProduct,
                                            "realize",
                                            $$v
                                          )
                                        },
                                        expression: "selectedProduct.realize"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "noResult" },
                                          slot: "noResult"
                                        },
                                        [_vm._v("Aucun Resultat trouvé.")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              selectedProduct.realize.uom
                                ? _c("div", { staticClass: "col-md-2" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Unité")
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            selectedProduct.realize.uom
                                              .designation,
                                          expression:
                                            "selectedProduct.realize.uom.designation"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text", disabled: "" },
                                      domProps: {
                                        value:
                                          selectedProduct.realize.uom
                                            .designation
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            selectedProduct.realize.uom,
                                            "designation",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "col-md-2" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Quantité ")
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: selectedProduct.qte,
                                      expression: "selectedProduct.qte"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "number", placeholder: "20" },
                                  domProps: { value: selectedProduct.qte },
                                  on: {
                                    keypress: function($event) {
                                      return _vm.isNumber($event)
                                    },
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          selectedProduct,
                                          "qte",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        return _vm.TotalAmount()
                                      }
                                    ]
                                  }
                                })
                              ]),
                              selectedProduct.realize
                                ? _c("div", { staticClass: "col-md-2" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Délai")
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.period,
                                          expression: "period"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        disabled: "",
                                        readonly: ""
                                      },
                                      domProps: { value: _vm.period },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.period = $event.target.value
                                        }
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "col-md-2  mt-4" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn text-danger",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteRealRow(i)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "far fa-trash-alt font-size-24"
                                    })
                                  ]
                                )
                              ])
                            ])
                          }),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c("div", { staticClass: "col text-right" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-info",
                                  attrs: { type: "button" },
                                  on: { click: _vm.AddformDataReal }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-plus-circle"
                                  }),
                                  _vm._v(" Ajouter")
                                ]
                              )
                            ])
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }