<script>
import appConfig from "@/app.config";
// import { FormWizard, TabContent } from "vue-form-wizard";
import Swal from "sweetalert2";
import { required} from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect'
import resources from "./resources";
import SideButtons from '../../../../../../components/side-buttons.vue';
import cancelation from '../../../../../../components/cancelation.vue';
import {getDropDownElementsByTypeApi,fetchProductsApi} from "@/api/common";
import {printSingleDocumentUrl} from "@/helpers/functions";

const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;
const after = (value) => value >= now;
const before = (value,vm) => value < vm.selectedContract.end_date;
const obg = (value) => value != 0;


export default {
    page: {
        title: "Ajout ODS Espaces Verts",
        meta: [{ name: "description", content: appConfig.description}],
    },
    import : {printSingleDocumentUrl},
    components: {/*Switches,*/resources,Multiselect,SideButtons,cancelation},
    props:{
      ODS:{
        type:Array,
        default:undefined
      }
    },
    data() {
      return {
        diffDays:0,
      print: false,
      products:[],
      equipments:[],
      title: "Ordre de Service",
      selectedBdv:0,
      addPersonel:false,
      contracts:[],
      selectedContract:'',
      bases:[],
      prests:[],
      selectedPrest:'',
      getEmit:false,
      info:false,
      add:false,
      save:false,
      saveToPending:false,
      confirm:false,
      validate:false,
      edit:false,
      emptyPrest:false,
      destroy:false,
      estimated:false,
      resetForm:false,
      cancel:false,
      cancelModal:false,
      Cancelation:{},
      ODS_info: {
        type_ODS:'green_space',
        status:'draft',
        Bdv:0,
        ID_CTT:0,
        prestataire:0,
        date_ODS:'',
        date_exec_ODS:'',
        period:"",
        services:[],
        comments:'',
        total_amount:0
      },
      submitted:false,
      RepasService:[],
      filtredRepas:[],
      switchers:[],    
      disabled:false,
      have:false,
      send:false,
      disableButtons:false,
      gs_services:[],
      selectedServices:[],
      items:{},
      planB:[],
      reset:false
    };
  },
  validations: {
        ODS_info:{
            Bdv:{
              required,
              obg
            },
            ID_CTT:{
              required,
              obg
            },
            prestataire:{
              required,
              obg
            },
            date_ODS:{
              required,
              after
            },
            date_exec_ODS:{
              required,
              after,
              // before
            },
            services:{
              required
            },
        } 
  },
  watch:{
    ODS:{
      handler(val){
        if(val[0] == undefined) {this.resetData(); this.have = false}
        else {
          if(this.add || this.edit){
          Swal.fire({
            title: 'Êtes-vous sûr ?',
            text: "Des elements sont en cours de saisie, êtes-vous de quitter ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui'
          }).then((result) => {
            if (result.isConfirmed) {
              var _this = this;
              this.resetForm=true;
              setTimeout(function(){ 
                // alert("Hello"); 
                _this.ODS_info.id            = val[0].id;
                _this.ODS_info.uuid          = val[0].uuid;
                _this.ODS_info.ref           = val[0].ref;
                _this.ODS_info.Bdv           = val[0].Bdv;
                _this.ODS_info.ID_CTT        = val[0].contract.id;
                _this.ODS_info.prestataire   = val[0].service_provider.id;
                _this.ODS_info.date_ODS      = val[0].date_ODS;
                _this.ODS_info.date_exec_ODS = val[0].date_exec_ODS;
                _this.ODS_info.period        = "";
                _this.ODS_info.done                =  val[0].done;
                _this.ODS_info.canceled_at         =  val[0].canceled_at;
                _this.ODS_info.canceled_reason     =  val[0].canceled_reason;
                _this.ODS_info.canceled_by         =  val[0].canceled_by;
                _this.ODS_info.cancel_by           =  val[0].cancel_by;
                _this.ODS_info.status        = val[0].status;
                _this.ODS_info.lifebase      = val[0].lifebase
                _this.ODS_info.total_amount  = val[0].totalAmnt.plain
                _this.ODS_info.items         = val[0].items
                _this.RepasService           = Object.keys(val[0].items);
                _this.filtredRepas           = val[0].items;
                // _this.Repas               = val[0].items
                _this.cancelModal = false;
                _this.cancel = false;
                _this.ODS_info.comments       =  val[0].comment;
                _this.selectedContract       = val[0].contract;
                _this.selectedBdv            = val[0].lifebase;
                _this.bases.push(_this.selectedBdv);
                _this.selectedPrest          = val[0].service_provider;

                _this.RepasService.forEach(element => {
                  if(element =='Entretien'){
                    let keys = Object.keys(_this.filtredRepas[element])
                    keys.forEach(x=>{
                      _this.ODS_info.services.push(_this.filtredRepas[element][x][0].service_type);
                    })
                  }
                  else _this.ODS_info.services.push(_this.filtredRepas[element][0].service_type);
                })

                // this.ODS_info.services.forEach( x => {
                  _this.selectedServices=(_this.gs_services.filter((elem) => (_this.ODS_info.services.includes(elem.id))))
                  // console.log(_this.selectedServices);
                // })

                _this.getServiceRepas();

                if(val[0].status == 'approved') _this.have = false
                else _this.have = true;
              }, 2000,val)
            }
            // else this.resetForm=true;
          })
        }
        else {
          this.ODS_info.id            = val[0].id;
          this.ODS_info.uuid          = val[0].uuid;
          this.ODS_info.ref           = val[0].ref;
          this.ODS_info.Bdv           = val[0].Bdv;
          this.ODS_info.ID_CTT        = val[0].contract.id;
          this.ODS_info.prestataire   = val[0].service_provider.id;
          this.ODS_info.date_ODS      = val[0].date_ODS;
          this.ODS_info.date_exec_ODS = val[0].date_exec_ODS;
          this.ODS_info.comments      =  val[0].comment;
          this.ODS_info.period        = "";
          this.ODS_info.done                =  val[0].done;
          this.ODS_info.canceled_at         =  val[0].canceled_at;
          this.ODS_info.canceled_reason     =  val[0].canceled_reason;
          this.ODS_info.canceled_by         =  val[0].canceled_by;
          this.ODS_info.cancel_by           =  val[0].cancel_by;
          this.ODS_info.status        = val[0].status;
          this.ODS_info.lifebase      = val[0].lifebase
          this.ODS_info.total_amount  = val[0].totalAmnt.plain
          this.ODS_info.items         = val[0].items
          this.RepasService           = Object.keys(val[0].items);
          this.filtredRepas           = val[0].items;
          // this.Repas               = val[0].items
          this.cancelModal = false;
          this.cancel = false;
          
          this.selectedContract       = val[0].contract;
          this.selectedBdv            = val[0].lifebase;
          this.bases.push(this.selectedBdv);
          this.selectedPrest          = val[0].service_provider;

          this.RepasService.forEach(element => {
            if(element =='Entretien'){
              let keys = Object.keys(this.filtredRepas[element])
              keys.forEach(x=>{
                this.ODS_info.services.push(this.filtredRepas[element][x][0].service_type);
              })
            }
            else this.ODS_info.services.push(this.filtredRepas[element][0].service_type);
          })

          // this.ODS_info.services.forEach( x => {
            this.selectedServices=(this.gs_services.filter((elem) => (this.ODS_info.services.includes(elem.id))))
            // console.log(this.selectedServices);
          // })

          this.getServiceRepas();

          if(val[0].status == 'approved') this.have = false
          else this.have = true;
        }
          
        }
        
      }
    },
    print:{
      handler(val){
        if(val && this.$can('validate_gs_ods')) this.printSingleDocument('single_gs_so', this.ODS_info.uuid);
        else if(val && !this.$can('validate_gs_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    add:{
      handler(val){
        if(val && this.$can('create_gs_ods')){
          // console.log(val);
          // console.log("clearForm");
          this.disabled = true;
          this.resetData();
          this.reset = false;
        }
        else if(val && !this.$can('create_gs_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    getEmit:{
      handler(val){
        if(val){
          // console.log("get emit total");
          this.getTotalAmountODS()
          
        }
      }
    },
    saveToPending:{
      handler(val){
        if(val && this.canAny(['create_gs_ods','edit_gs_ods'])){
          // console.log("Save To Pending");
              this.ODS_info.status = "pending_confirmation";
              if(this.ODS_info.id) {
                  Swal.fire({
                    title: 'Êtes vous sûr ?',
                    text: "d'envoyer cet ODS pour confirmation ",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2b8a3e',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Annuler',
                    confirmButtonText: 'Oui',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.onUpdate();
                    }
                  })
              } 
              else this.onComplete();
        }
        else if(val && !this.canAny(['create_gs_ods','edit_gs_ods'])) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    edit:{
      handler(val){
        if( ( val && this.$can('edit_gs_ods') ) || ( val && this.ODS_info.status==('confirmed' || 'approved') && this.$can('override_gs_ods') ) ){
          this.disabled = true;
          this.reset = false;
        }
        else if(val && !this.$can('edit_gs_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    cancel:{
      handler(val){
        if( ( val && this.$can('override_gs_ods') && this.ODS_info.done != 1)){
          this.cancelODS()
        }
        else if(val && !this.$can('override_gs_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    reset:{
        handler(val){
            if(val) {
                // console.log('reset');
                this.resetData();
                this.disabled=false;
                this.have=false;
            }
        }
    },
    confirm:{
      handler(val){
        if(val && this.$can('confirm_gs_ods')){
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de confirmer cet ODS ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.onValidate("Confirm");
            }
          })
          
        }
        else if(val && !this.$can('confirm_gs_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    reject:{
      handler(val){
        if(val && this.$can('confirm_gs_ods')){
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de rejeter cet ODS ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.onValidate("Reject");
            }
          })
        }
        else if(val && !this.$can('confirm_gs_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    validate:{
      handler(val){
        if(val && this.$can('validate_gs_ods')){
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de Valider cet ODS ? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.onValidate("Validate");
            }
          })
          
        }
        else if(val && !this.$can('validate_gs_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    destroy:{
      handler(val){
        if(val && this.$can('delete_gs_ods') || ( val && this.ODS_info.status==('pending_confirmation'||'confirmed' || 'approved') && this.$can('override_gs_ods') ))this.onDelete();
        else if(val && !this.$can('delete_gs_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    }
  },
  mounted(){
    this.ODS_info.date_ODS = this.TodayDate();
    this.ODS_info.date_exec_ODS = this.TodayDate();

    this.getlistContracts();
    this.getListGreenSpace_services();
  },

  methods:{
    printSingleDocument(doc, uid){
      printSingleDocumentUrl(doc, uid);
      this.print =false;
    },
    resetData(btn = null){
        // this.add=false;
        this.edit=false;
        this.saveToPending=false;
        this.confirm=false;
        this.validate=false;
        this.reject=false;
        this.destroy=false;
        
        this.ODS_info = {
        type_ODS:'green_space',
        status:'draft',
        Bdv:0,
        ID_CTT:0,
        prestataire:0,
        date_ODS:now,
        date_exec_ODS:now,
        services:[],
        comments:''
      };
      this.selectedContract=[];
      this.selectedBdv=[];
      this.selectedPrest=[];
      this.RepasService = [];
      this.filtredRepas = [];
      this.planB = [];
      this.selectedServices=[];
      this.items={};
      if(btn){
        this.add = false;
        this.edit = false;
      }
    },
    getListGreenSpace_services(){
      getDropDownElementsByTypeApi('greenspacce_service_type')
          .then(res => {
            // console.log(res);
            this.gs_services = res.data.original.list;
          })
          .catch(error => console.log(error))
    },
    getlistContracts(){
      this.$http.post('/ods/listContractsBytype',{type:"green_space"})
      .then(response => this.contracts = response.data)
      .catch(error=> console.log(error))
    },
    getLifeBases(){
      this.$http.post('/base/lifebases/list')
      .then(response => {
          this.bases = response.data.original.list;
      })
      .catch(error => console.log(error))
    },
    onComplete(){
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
          // console.log("ERROR");
      }
      else {
        var msg = "";
        if(this.ODS_info.status=='draft') msg ="d'enregister cet ODS en tant que Brouillon?"
        else msg="d'enregister cet ODS pour confirmation ?"
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: msg,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$http.post('/ods/OdsRestauration/store',{
                  ods:this.ODS_info, items:this.items
              })
              .then(response => {
                  this.send = true;
                  this.$emit('odsvalues',true);
                  Swal.fire("Félicitations!", response.data.ref+" enregistré", "success");
                  this.resetData();
                  this.send=false;
                  this.add=false;
                  this.reset=true;
                  this.$v.$reset();
              })
              .catch(error => console.log(error))
            }
          })
        }
    },
    onUpdate(){
          // console.log("Valid");
          this.$http.post('/ods/OdsRestauration/update',{
              ods:this.ODS_info, items:this.items
          })
          .then(response => {
              this.send = true;
              this.$emit('odsvalues',true);
              Swal.fire("Félicitations!", this.ODS_info.ref+" à jour", "success");
              this.resetData();
              this.send=false;
              this.reset=true;
              this.edit=false;
          })
          .catch(error => console.log(error))
      
    },
    onValidate(data){
          this.$http.post('/ods/OdsRestauration/validate',{
              ods:this.ODS_info,status:data
          })
          .then(response => {
              this.send = true;
              this.$emit('odsvalues',true);
              if(data == "Confirm") Swal.fire("Félicitations!", this.ODS_info.ref+" Confirmé", "success");
              if(data == "Validate") Swal.fire("Félicitations!", this.ODS_info.ref+" Approuvé", "success");
              if(data == "Reject") Swal.fire("Félicitations!", this.ODS_info.ref+" Rejeté", "success");
              
              this.resetData();
              this.send=false;
              this.validate=false;
              this.confirm=false;
              this.have=false;
          })
          .catch(error => console.log(error))
    },
    onDelete(){
      if((this.ODS_info.status == ('draft' || 'rejected')) || (this.ODS_info.status==('pending_confirmation'||'confirmed' || 'approved') && this.$can('override_gs_ods') )){
         var _this = this;
          Swal.fire({
            title: "Êtes-vous sûr de supprimer l'ODS: " + _this.ODS_info.ref + "?",
            text: "Cette Action est irréversible",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor:"#f46a6a",
            cancelButtonColor:  "#34c38f",
            confirmButtonText: 'Oui!',
            cancelButtonText: 'Annuler!',
          }).then((result) => {
            if (result.value) {
              this.$http
                .post("ods/OdsRestauration/delete",{ods:this.ODS_info})
                .then((res) => {
                  // console.log(res);
                  var status = res.data.status;
                  switch (status) {
                    case 200:
                      _this.$toast.success(res.data.msg);
                      this.$emit('odsvalues',res.data);
                      // this.$emit('refresh',true);
                      this.resetData();
                      this.disabled = false;
                      break;

                    case 500:
                      this.$toast.warning(res.data.msg);
                      break;
                  }
                })
                .catch((error) => {
                  _this.$toast.error(error.message);
                })
                .finally(() => {});
            }
          });
          this.destroy=false;
      }
      else this.$toast.warning("Vous ne pouvez pas supprimé un ODS déja confirmé ou approuvé");
    },
    getServiceRepas(){
      this.$http.post('/ods/OdsRestauration/listRepas',{type:this.ODS_info.type_ODS,contract_id:this.ODS_info.ID_CTT,Bdv:this.ODS_info.Bdv,period:this.ODS_info.services})
      .then(response => {
        let List = Object.keys(response.data);
        if(List.length > 0){
            this.RepasService={};
            List.forEach(element => {
              // console.log(element);
              this.items[element] = undefined;
            })

            if(this.ODS != undefined && this.ODS[0] != undefined && this.ODS[0].id){
              this.planB = response.data;
            }
            else {
              this.RepasService = List;
              this.filtredRepas = response.data;
              this.planB = response.data;
            }
            this.emptyPrest = false;
          }
          else {
            Swal.fire(
              'Vide',
              'Aucune Prestation Trouvé',
              'warning'
            )
            this.emptyPrest = true;
          }
         
      })
      .catch(error => console.log(error))
    },
    fetchProdusList(){
      fetchProductsApi()
      .then(res => {this.products = res.data.data})
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },
    TodayDate(){
        var td, dt = new Date;
        td = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

        return td;
    },
    getContractID(contract){
      this.selectedBdv=[];
      this.ODS_info.services=[];
      this.selectedServices=[];
      this.ODS_info.ID_CTT      = contract.id;
      this.bases=contract.camps_services.map(element => {return element.camp});
      this.prests.push(contract.supplier);
      this.selectedPrest = contract.supplier;
      if(this.bases.length == 1) {
        this.selectedBdv = this.bases[0];
        this.getLifeBaseID(this.bases[0]);
      }
      this.ODS_info.prestataire = contract.supplier.id;
      this.items={};
      // console.log(this.bases);
    },
    getLifeBaseID(lifebase){
      this.ODS_info.Bdv      = lifebase.id;
      this.ODS_info.services=[];
      this.selectedServices=[];
      this.items={};
    },
    getServicesID(service){
      // this.selectedServices.push(service)
      this.ODS_info.services.push(service.id)
      // console.log(this.selectedServices);
      this.getServiceRepas();
    },
    removeServicesID(service){
      let i = this.ODS_info.services.indexOf(service.id)
      this.ODS_info.services.splice(i,1)
      // console.log(this.selectedServices);
      this.getServiceRepas();
    },
    getTotalAmountODS(){
      var total = 0;
      var keys = Object.keys(this.items)
      keys.forEach(element => {
        if(this.items[element] && this.items[element] != undefined && Array.isArray(this.items[element])) {
          total=0;
          this.items[element].forEach( x => {total += x.amount})
        }
        else if(this.items[element] && this.items[element] != undefined && !Array.isArray(this.items[element])){
          total=0;
          var index = Object.keys(this.items[element])
          index.forEach(x => {
            // console.log(this.items[element][x]);
            this.items[element][x].forEach(y => (total += y.amount))
          })
        }
        
      })
      // console.log(total);
      this.getEmit=false;
      this.ODS_info.total_amount = total;

      return total;
    },
    cancelODS(){
      var _this = this;
          Swal.fire({
            title: "Êtes-vous sûr d'annuler l'ODS: " + _this.ODS_info.ref + "?",
            text: "Cette Action est irréversible",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor:"#f46a6a",
            cancelButtonColor:  "#34c38f",
            confirmButtonText: 'Oui!',
            cancelButtonText: 'Annuler!',
          }).then((result) => {
            if (result.value) {
              this.$http
                .post('/ods/OdsRestauration/cancel',{id:_this.ODS_info.id,motif:_this.Cancelation.motif})
                .then((res) => {
                  // console.log(res);
                  var status = res.data.status;
                  switch (status) {
                    case 200:
                      _this.$toast.success(res.data.msg);
                      this.$emit('odsvalues',res.data);
                      this.Cancelation="";
                      this.resetData();
                      this.disabled = false;
                      this.cancelModal = true;
                      break;

                    case 500:
                      this.$toast.warning(res.data.msg);
                      break;
                  }
                })
                .catch((error) => {
                  _this.$toast.error(error.message);
                })
                .finally(() => {});
            }
            else {
              this.Cancelation="";
            }
          });
    },
    reformDat(){
      // console.log(this.ODS_info.date_exec_ODS.split('-'));
      let d1 = new Date(this.ODS_info.date_exec_ODS.split('-')[0],this.ODS_info.date_exec_ODS.split('-')[1]-1,this.ODS_info.date_exec_ODS.split('-')[2]);
      let d2 = new Date(this.ODS_info.date_ODS.split('-')[0],this.ODS_info.date_ODS.split('-')[1]-1,this.ODS_info.date_ODS.split('-')[2]);
      let diff = (d1.getTime() - d2.getTime()) / (1000*3600*24);

      this.diffDays = diff+" jours";
    }
  }
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <!-- <b-tabs content-class="mt-0">
          <b-tab title="Informations Générales" active> -->
            <SideButtons :block="disableButtons" :reset="reset" @print="print = $event"
              :canEdit="!have" :select="have" :save="send" :ODS="true" :service_order="ODS_info"  @add="add = $event" 
              @saveToPending="saveToPending = $event" @edit=" edit = $event"  @confirm=" confirm = $event" 
              @validate=" validate = $event" @resetEmit="resetForm = $event" @delete=" destroy = $event" />
                <div class="">
                  <div class="">
                  <cancelation v-if="ODS_info.id" :closeModal="cancelModal" :ods="ODS_info" @cancel_reason="Cancelation = $event" @cancel="cancel=$event"></cancelation>
                    <div class="p-2">
                          <div>
                            <fieldset :disabled="!disabled">
                                <!-- <b-alert show variant="info"> <i class="mdi mdi-information-outline"></i> Les champs obligatoires sont marqués avec (*) </b-alert> -->
                                <div class="row">
                                      <div class="col-lg-4">
                                        <div class="form-group">
                                          <label for="formrow-password-input">N° Contrat</label>
                                          <multiselect v-model="selectedContract" :class="{'disabledMS':!disabled}" :value="ODS_info.ID_CTT" @select="getContractID" track-by="id" label="ref" placeholder="" :options="contracts" :searchable="false" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.ref }}</strong></template>
                                          </multiselect>
                                          <div v-if="submitted && $v.ODS_info.ID_CTT.$error">
                                              <span class="text-danger" v-if="!$v.ODS_info.ID_CTT.obg">Le Contrat est Obligatoire</span>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-4">
                                        <div class="form-group">
                                          <label for="formrow-inputCity"
                                            >Nom de la Base *</label
                                          >
                                          <multiselect v-model="selectedBdv" @select="getLifeBaseID" :class="{'disabledMS':!disabled}" :disabled="bases.length == 1" :value="ODS_info.Bdv" track-by="id" label="name" placeholder="" :options="bases" :searchable="false" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                          </multiselect>
                                          
                                          <div v-if="submitted && $v.ODS_info.Bdv.$error">
                                              <span class="text-danger" v-if="!$v.ODS_info.Bdv.obg">La base de vie est Obligatoire</span>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-4">
                                          <label for="">Prestataire *</label>
                                          <multiselect v-model="selectedPrest" :class="{'disabledMS':!disabled}" :disabled="true" :value="ODS_info.prestataire" track-by="id" label="prestname" placeholder="" :options="prests" :searchable="false" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.prestname }}</strong></template>
                                          </multiselect>
                                          
                                      </div>
                                  </div>
                                  
                                  <div class="row">
                                      <div class="col-lg-4">
                                          <label for="">Date ODS</label>
                                          <input type="date" class="form-control" v-model="ODS_info.date_ODS" readonly :class="{'is-invalid': $v.ODS_info.date_ODS.$error || ODS_info.date_exec_ODS > selectedContract.end_date}" >
                                          <div v-if="submitted && $v.ODS_info.date_ODS.$error">
                                              <span class="text-danger" v-if="!$v.ODS_info.date_ODS.after">la date doit être superieure ou égal à aujourd'hui</span>
                                          </div>
                                      </div>
                                      <div class="col-lg-4">
                                          <label for="">Date execution</label>
                                          <input type="date" class="form-control" v-model="ODS_info.date_exec_ODS" :class="{'is-invalid': $v.ODS_info.date_exec_ODS.$error}" @input="reformDat">
                                          <div v-if="submitted && $v.ODS_info.date_exec_ODS.$error">
                                              <span class="text-danger" v-if="!$v.ODS_info.date_exec_ODS.after">la date doit être superieure ou égal à aujourd'hui</span>
                                              <span class="text-danger" v-if="!$v.ODS_info.date_exec_ODS.before">la date doit être inferieure à la date fin de contract</span>
                                          </div>
                                          <div v-if="ODS_info.date_exec_ODS > selectedContract.end_date">
                                              <!-- <span class="text-danger" v-if="!$v.ODS_info.date_exec_ODS.after">la date doit être superieure ou égal à aujourd'hui</span> -->
                                              <span class="text-danger">la date doit être inferieure à la date fin de contract</span>
                                          </div>
                                      </div>
                                      <div class="col-lg-4">
                                          <label for="">Type de Service *</label>
                                          

                                          <multiselect v-model="selectedServices" :class="{'disabledMS':!disabled}" @select="getServicesID" @remove="removeServicesID" :deselect-label="''" :disabled="!disabled" :multiple="true"  track-by="id" label="designation" placeholder="" :options="gs_services" :searchable="false" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.designation }}</strong></template>
                                          </multiselect>
                                      </div>
                                  </div>
                                  

                                  <div class="row mt-2">
                                      <div class="col-md-12">
                                        <div class="form-group">
                                          <label for="formrow-inputCity">Commentaires</label>
                                          <textarea
                                            v-model="ODS_info.comments"
                                            class="form-control"
                                            cols="30"
                                            rows="1" 
                                            required
                                          ></textarea>
                                        </div>
                                      </div>
                                  </div>

                                <!-- end row -->
                                </fieldset>
                          </div>
                          <!-- end card-body -->
                    </div>

                    <div class="p-3">
                      <div class="row mt-2" v-for="(rep,i) in RepasService" :key="i">
                        <div class="col">
                          <resources :period="diffDays" :estimated="estimated" :disable="disabled" :key="rep" :pos="rep" :planB="planB" :service="filtredRepas" :send="send" @getvalues="items[`${rep}`] = $event" @getvaluesEmitted="getEmit = $event" />
                        </div>
                        
                      </div>
                    </div>
                </div>
                <!-- end card-body -->
                <div class="row">
                  <div class="col text-right mr-2 mb-3" v-if="add">
                    <b-button :disabled="$v.ODS_info.$invalid || RepasService.length == 0 || ODS_info.date_exec_ODS > selectedContract.end_date" @click="onComplete" variant="primary" class="btn-label mr-2">
                        <i class="far fa-save text-white label-icon"></i> Enregitrer
                    </b-button>

                    <b-button  @click="reset = true" variant="light" class="btn-label">
                        <i class="fas fa-times-circle text-danger label-icon"></i> Annuler 
                    </b-button>
                  </div>

                  <div class="col text-right mr-2 mb-3" v-if="edit && ODS_info.id">
                    <b-button  @click="onUpdate" :disabled="RepasService.length == 0 || ODS_info.date_exec_ODS > selectedContract.end_date" variant="primary" class="btn-label mr-2">
                        <i class="far fa-save text-white label-icon"></i> Enregitrer 
                    </b-button> 
                    
                    <b-button  @click="reset = true" variant="light" class="btn-label">
                        <i class="fas fa-times-circle text-danger label-icon"></i> Annuler 
                    </b-button>
                  </div>
                  
                </div>
              </div>
        
      </div>
    </div>
    <!-- </Layout> -->
</template>